import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BlockText from '../components/block-text'
import GraphQLErrorList from '../components/graphql-error-list'

import styles from './about.module.css'

export const query = graphql`
  query ContactPageQuery {
    page: sanityContact {
      _rawBodyText
    }
  }
`

const Contact = props => {
  const {data, errors} = props
  const page = (data || {}).page

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout>
      <div className={styles.page}>
        <div className={styles.pagecontentcontact}>
          <SEO title='Contact' />
          <BlockText blocks={page._rawBodyText} />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
